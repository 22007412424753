<template>
  <div class="flex flex-col min-vh-100">
    <header
      class="border-b-2 border-t-0 border-r-0 border-l-0 border-solid border-gray-200 bg-gray-50"
    >
      <div class="container">
        <div class="flex justify-between items-center">
          <div class="font-bold text-3xl py-2 mr-2">
            <a href="/">
              <img src="/logo.svg" alt="HODO Pro" />
            </a>
          </div>
          <div class="flex items-center text-lg ml-auto">
            <span
              class="block p-2 cursor-pointer transition-all duration-200"
              :class="$lang === LANGUAGE_CODES_NAME.VI.toLowerCase() ? 'text-black' : 'text-gray-400'"
              @click="setLanguage(LANGUAGE_CODES_NAME.VI.toLowerCase())"
            >VI</span>
            <span class="mx-1">/</span>
            <span
              class="block p-2 cursor-pointer transition-all duration-200"
              :class="$lang === LANGUAGE_CODES_NAME.EN.toLowerCase() ? 'text-black' : 'text-gray-400'"
              @click="setLanguage(LANGUAGE_CODES_NAME.EN.toLowerCase())"
            >EN</span>
          </div>
        </div>
      </div>
    </header>
    <div v-loading="isLoading" class="flex flex-1 w-full">
      <div class="container">
        <div class="py-4" v-if="Object.keys(confCircleDetail).length">
          <div class="text-center">
            <h1>{{ circleMultiLang.name }}</h1>
            <div class="flex items-center justify-center mb-2">
              <template v-if="$lang === LANGUAGE_CODES_NAME.VI.toLowerCase()">
                <span>{{ getTimeConf(confCircleDetail.conf_date) }}</span>
                <span class="mx-1">-</span>
              </template>
              <span class="letter-style">{{ getDateConf(confCircleDetail.conf_date) }}</span>
            </div>
            <div class="mb-4">
              <span>{{ circleMultiLang.location }}</span>
              <span class="mx-2">-</span>
              <a
                :href="`https://www.google.com/maps/search/${confCircleDetail.conference_location}/`"
                target="_blank"
              >{{ confCircleDetail.conference_location }}</a>
            </div>
            <div class="mb-4">
              <div>
                <div
                  class="text-lg mb-3"
                  v-html="$t('conference.lbl_join_live_at_hodo_pro_app', { 0: `<strong>HODO Pro App</strong>`})"
                />
                <div class="mb-4">
                  <router-link
                    to="/bsi"
                    target="_blank"
                    class="inline-block rounded-lg bg-pri-600 hover:bg-blue-600 text-white py-2 px-3 text-xl hover:no-underline transition-all duration-200"
                  >
                    <i class="el-icon-download align-middle mr-2" />
                    <span class="align-middle" v-html="$t('conference.lbl_download_hodo_pro_app')"></span>
                  </router-link>
                </div>
              </div>
              <div>
                <div class="text-lg mb-2" v-html="$t('conference.lbl_or_join_on_a_web_browser')" />
                <div class="mb-4">
                  <router-link
                    :to="`/_conference/${confCircleDetail.code.toLowerCase()}`"
                    target="_blank"
                    class="text-pri-600 text-xl font-bold hover:no-underline"
                  >
                    <span class="align-middle">{{ $t('HODO Pro Website') }}</span>
                  </router-link>
                </div>
              </div>
            </div>
            <div>
              <div class="relative custom-line-style mb-3">
                <span
                  class="relative inline-block bg-white px-3"
                >{{ $t('conference.lbl_conference_access_instrutions') }}</span>
              </div>
              <div class="flex justify-center text-lg mb-3">
                <div
                  v-for="(tab, index) in tabs"
                  :key="index"
                  class="px-3 transition-all duration-200 cursor-pointer"
                  :class="`${index === 0 ? 'border-r-2 border-t-0 border-b-0 border-l-0 border-solid border-gray-200' : ''} ${tabIndex === index ? 'text-primary' : 'text-gray-400'}`"
                  @click="setTabIndex(index)"
                >{{ tab }}</div>
              </div>
              <div>
                <div v-if="tabIndex === 0">
                  <div>
                    <div class="flex justify-center">
                      <p class="font-bold whitespace-nowrap mr-2">{{ $t('conference.lbl_step') }} 1:</p>
                      <p class="text-left" v-html="$t('conference.lbl_step_app_1')"></p>
                    </div>
                    <p>
                      <img
                        src="../../assets/images/conference-tutorial/img_app_1.jpg"
                        :alt="`${$t('conference.lbl_step')} 1`"
                      />
                    </p>
                  </div>
                  <div>
                    <div class="flex justify-center">
                      <p class="font-bold whitespace-nowrap mr-2">{{ $t('conference.lbl_step') }} 2:</p>
                      <p class="text-left" v-html="$t('conference.lbl_step_app_2')"></p>
                    </div>
                    <p>
                      <img
                        src="../../assets/images/conference-tutorial/img_app_2.jpg"
                        :alt="`${$t('conference.lbl_step')} 2`"
                      />
                    </p>
                  </div>
                  <div>
                    <div class="flex justify-center">
                      <p class="font-bold whitespace-nowrap mr-2">{{ $t('conference.lbl_step') }} 3:</p>
                      <p
                        class="text-left"
                        v-html="$t('conference.lbl_step_app_3', { 0: `<strong>${circleMultiLang.name}</strong>`, 1: `<strong>${circleGroupMultiLang.title}</strong>` })"
                      ></p>
                    </div>
                    <p>
                      <img
                        src="../../assets/images/conference-tutorial/img_app_3.jpg"
                        :alt="`${$t('conference.lbl_step')} 3`"
                      />
                    </p>
                  </div>
                  <div>
                    <div class="flex justify-center">
                      <p class="font-bold whitespace-nowrap mr-2">{{ $t('conference.lbl_step') }} 4:</p>
                      <p class="text-left" v-html="$t('conference.lbl_step_app_4')"></p>
                    </div>
                    <p>
                      <img
                        src="../../assets/images/conference-tutorial/img_app_4.jpg"
                        :alt="`${$t('conference.lbl_step')} 4`"
                      />
                    </p>
                  </div>
                </div>
                <div v-if="tabIndex === 1">
                  <div>
                    <div class="flex justify-center">
                      <p class="font-bold whitespace-nowrap mr-2">{{ $t('conference.lbl_step') }} 1:</p>
                      <p
                        class="text-left"
                        v-html="$t('conference.lbl_step_web_1', { 0: `<a href='https://hodo.pro/#/' target='_blank'>Hodo.pro</a>`})"
                      ></p>
                    </div>
                    <p>
                      <img
                        src="../../assets/images/conference-tutorial/img_web_1.jpg"
                        :alt="`${$t('conference.lbl_step')} 1`"
                      />
                    </p>
                  </div>
                  <div>
                    <div class="flex justify-center">
                      <p class="font-bold whitespace-nowrap mr-2">{{ $t('conference.lbl_step') }} 2:</p>
                      <p
                        class="text-left"
                        v-html="$t('conference.lbl_step_web_2', { 0: `<strong>${circleMultiLang.name}</strong>`, 1: `<strong>${circleGroupMultiLang.title}</strong>` })"
                      ></p>
                    </div>
                    <p>
                      <img
                        src="../../assets/images/conference-tutorial/img_web_2.jpg"
                        :alt="`${$t('conference.lbl_step')} 2`"
                      />
                    </p>
                  </div>
                  <div>
                    <div class="flex justify-center">
                      <p class="font-bold whitespace-nowrap mr-2">{{ $t('conference.lbl_step') }} 3:</p>
                      <p class="text-left" v-html="$t('conference.lbl_step_web_3')"></p>
                    </div>
                    <p>
                      <img
                        src="../../assets/images/conference-tutorial/img_web_3.jpg"
                        :alt="`${$t('conference.lbl_step')} 3`"
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="py-4 max-w-xl mx-auto"
          v-else-if="!isLoading && Object.keys(confCircleDetail).length === 0"
        >
          <el-alert
            class="py-3"
            :title="$t('title.not_found')"
            :closable="false"
            type="warning"
            show-icon
            center
          ></el-alert>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LANGUAGE_CODES_NAME, LANGUAGE_CODES_FOR_DISEASE } from '@/lang'

export default {
  name: 'ConferenceTutorial',
  data () {
    return {
      isLoading: true,
      confCircleDetail: {},
      LANGUAGE_CODES_NAME,
      LANGUAGE_CODES_FOR_DISEASE,
      tabs: ['App', 'Web'],
      tabIndex: 0
    }
  },
  async created () {
    this.getConferenceCircleDetail()
  },
  watch: {
    '$route.params': {
      handler (data) {
        this.getConferenceCircleDetail(data)
      }
    }
  },
  computed: {
    circleMultiLang () {
      const langValue = LANGUAGE_CODES_FOR_DISEASE[this.$lang.toUpperCase()]
      const langData =
        this.confCircleDetail?.multilang?.find(
          item => item?.lang === langValue
        ) || {}
      return langData
    },
    circleGroupMultiLang () {
      const langValue = LANGUAGE_CODES_FOR_DISEASE[this.$lang.toUpperCase()]
      const langData =
        this.confCircleDetail?.confer_group?.multilang?.find(
          item => item?.lang === langValue
        ) || {}
      return langData
    }
  },
  methods: {
    setLanguage (lang) {
      this.$lang = lang
    },
    async getConferenceCircleDetail (data) {
      try {
        this.isLoading = true
        const code = data?.code || this.$route.params?.code
        const res = await this.$rf
          .getRequest('ConferenceRequest')
          .getConferenceCirclePublic(code)
        if (!res?.data) return
        this.confCircleDetail = res.data
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    getTimeConf (date) {
      return window.moment(date).format('HH:mm')
    },
    getDateConf (date) {
      let dateStr = window.moment(date).locale(this.$lang)

      if (this.$lang === LANGUAGE_CODES_NAME.VI.toLowerCase()) {
        dateStr = dateStr.format('dddd, -DD/MM/YYYY')
        dateStr = dateStr.replace('-', 'ngày ')
        return dateStr
      }

      return dateStr.format('LLL')
    },
    setTabIndex (value) {
      this.tabIndex = value
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .letter-style::first-letter {
    text-transform: uppercase;
  }
  .custom-line-style {
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 0;
      background-color: #e0e0e0;
    }
  }
}
</style>
